import { render, staticRenderFns } from "./ConfigAlertSlack.vue?vue&type=template&id=498f551a&scoped=true"
import script from "./ConfigAlertSlack.vue?vue&type=script&lang=js"
export * from "./ConfigAlertSlack.vue?vue&type=script&lang=js"
import style0 from "./ConfigAlertSlack.vue?vue&type=style&index=0&id=498f551a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "498f551a",
  null
  
)

export default component.exports